.view-more-section {
    .marquee-list {
        gap: 16px;
        .rfm-marquee,
        .rfm-initial-child-container {
            gap: 16px;
        }
    }
    .view-more-slide {
        max-width: 240px;
        width: 100%;
        figure {
            border-radius: 14px;
            overflow: hidden;
            position: relative;
        }
    }
    .view-more-block {
        h2 {
            background: linear-gradient(90deg, #5C45A2 1.52%, #AA439D 34.85%, #D1284B 70.25%, #F9A526 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }
}